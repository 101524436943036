const initialState = {
    productData: [],
    total: 1,
    params: {},
    orderData: [],
    data: []
    // allData: [],
    // designationData: [],
    // reportingManager: [],
    // change_PasswordData:[],
  }
  
  const trendingProductReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_TRENDING_PRODUCT':
        return {
          ...state,
          allData: action.allData,
          productData: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_TRENDING_ORDERED':
          return {
            ...state,
            allData: action.allData,
            orderData: action.data,
            total: action.totalPages,
            params: action.params
          }
      case 'EXPORT_DATA':
            return {
              ...state,
              allData: action.allData,
              data: action.data,
              total: action.totalPages,
              params: action.params
            }
      case 'EXPORT_ORDERED_DATA':
            return {
              ...state,
              allData: action.allData,
              data: action.data,
              total: action.totalPages,
              params: action.params
            }
    
      default:
        return { ...state }
    }
  }
  export default trendingProductReducer
  