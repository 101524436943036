const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    // reportingManager: [],
    // change_PasswordData:[],
  }
  
  const purchaseReportMasterReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_PURCHASE_REPORT':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'DOWNLOAD_EXCEL':
            return {
              ...state,
              allData: action.allData,
              data: action.data,
              total: action.totalPages,
              params: action.params
            }
        case 'SHORTAGE_EXCEL':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      
      default:
        return { ...state }
    }
  }
  export default purchaseReportMasterReducer
  