/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';
const permission = JSON.parse(localStorage.getItem("userData"))
	? JSON.parse(localStorage.getItem("userData")).authentication
	: {};
//  Dashboard 1
const AsyncDashboard1Component = Loadable({
	loader: () => permission && permission.Dashboard !== undefined && permission.Dashboard.includes("Dashboard") ? import("views/Dashboard1") : import("views/Welcome"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncWelcomeComponent = Loadable({
	loader: () => import("views/Welcome"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Master user //
const AsyncUserMasterComponent = Loadable({
	loader: () => import("views/Master/UserMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("views/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("views/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
	loader: () => import("views/Error/403"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("views/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
	loader: () => import("views/SampleForm"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncFormInformationComponent = Loadable({
	loader: () => import("views/Master/FormInformation/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Master banner //
const AsyncBannerMasterComponent = Loadable({
	loader: () => import("views/Master/BannerMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynCategoryComponent = Loadable({
	loader: () => import("views/Master/CategoryMaster/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynSubCategoryComponent = Loadable({
	loader: () => import("views/Master/SubCategoryMaster/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// const AsynPurchaseComponent = Loadable({
// 	loader: () => import("views/MaterialManagement/PurchaseMaster"),
// 	loading: () => <HulkPageLoader />,
// 	delay: 3000,
// });
// const AsynPurchaseReportComponent = Loadable({
// 	loader: () => import("views/MaterialManagement/PurchaseReport"),
// 	loading: () => <HulkPageLoader />,
// 	delay: 3000,
// });
// const AsynPurchaseHistoryComponent = Loadable({
// 	loader: () => import("views/MaterialManagement/PurchaseHistory"),
// 	loading: () => <HulkPageLoader />,
// 	delay: 3000,
// });

const AsynPurchaseComponent = Loadable({
	loader: () => import("views/MaterialManagement/PurchaseMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynPurchaseHistoryComponent = Loadable({
	loader: () => import("views/MaterialManagement/PurchaseHistory"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynPurchaseReportComponent = Loadable({
	loader: () => import("views/MaterialManagement/PurchaseReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const AsynPromocodeComponent = Loadable({
	loader: () => import("views/Master/Promocode/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynSettingComponent = Loadable({
	loader: () => import("views/Master/Setting/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynNotificationComponent = Loadable({
	loader: () => import("views/Master/Notification/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynProductComponent = Loadable({
	loader: () => import("views/Master/Product/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

//list
const AsynOrderListComponent = Loadable({
	loader: () => import("views/Reports/Orders"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynCustomerListComponent = Loadable({
	loader: () => import("views/Customers/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// const AsynProductAvailibilityUpdateComponent = Loadable({
// 	loader: () => import("views/ProductAvailabilityUpdate/"),
// 	loading: () => <HulkPageLoader />,
// 	delay: 3000,
// });
const AsynBranchSellsReportComponent = Loadable({
	loader: () => import("views/Reports/BranchSellsReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynSellsReportComponent = Loadable({
	loader: () => import("views/Reports/SalesReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynAbundantCartReportComponent = Loadable({
	loader: () => import("views/Reports/AbundantCartReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynTrendingProductReportComponent = Loadable({
	loader: () => import("views/Reports/TrendingProductReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynBlogsComponent = Loadable({
	loader: () => import("views/Master/Blogs"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynUnitComponent = Loadable({
	loader: () => import("views/Master/UnitMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynSecondSubCategoryComponent = Loadable({
	loader: () => import("views/Master/SecondSubCategoryMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynSupplierComponent = Loadable({
	loader: () => import("views/Master/SupplierMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynPopupComponent = Loadable({
	loader: () => import("views/Master/Popup/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


export {
	AsyncDashboard1Component,
	AsyncUserMasterComponent,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncErrorPage403Component,
	AsyncSampleFormComponent,
	AsyncFormInformationComponent,
	AsynCategoryComponent,
	AsynPromocodeComponent,
	AsynSettingComponent,
	AsynNotificationComponent,
	AsynProductComponent,
	AsynOrderListComponent,
	AsynCustomerListComponent,
	// AsynProductAvailibilityUpdateComponent,
	AsynBranchSellsReportComponent,
	AsynSellsReportComponent,
	AsynSubCategoryComponent,
	AsyncBannerMasterComponent,
	AsynPopupComponent,
	AsynBlogsComponent,
	AsynUnitComponent,
	AsynPurchaseComponent,
	AsynPurchaseHistoryComponent,
	AsynPurchaseReportComponent,
	AsynSecondSubCategoryComponent,
	AsynSupplierComponent,
	AsynAbundantCartReportComponent,
	AsynTrendingProductReportComponent,
	AsyncWelcomeComponent
};