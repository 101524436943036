/**
 * Horizontal Menu
*/
import React, { useEffect,useState } from 'react';
import { Box } from '@material-ui/core';

import MenuItems from 'routes/MenuItems';

import NavMenuItem from './NavMenuItem';

function HorizontalMenu(){
	const [navLinks] = useState(MenuItems);
	const [general,setGeneral] = useState(null);
	const [setModules] = useState(null);
	const [setComponents] = useState(null);
	const [setApplication] = useState(null);
	const [setProduct] = useState(null);
	const [setBlogs] = useState(null);
	const [setMaterial] = useState(null);
	const [setReport] = useState(null);
	const [setCustomer] = useState(null);
	const [setOrder] = useState(null);

	useEffect(()=>{
		setCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const setCategory = () => {
		let category1 = navLinks.filter((item, i) => {
			return item.category === 'general';
		})
		let category2 = navLinks.filter((item, i) => {
			return item.category === 'modules';
		})
		let category3 = navLinks.filter((item, i) => {
			return item.category === 'materialManagement';
		})
		let category4 = navLinks.filter((item, i) => {
			return item.category === 'applications';
		})
		let category5 = navLinks.filter((item, i) => {
			return item.category === 'products';
		})
		let category6 = navLinks.filter((item, i) => {
			return item.category === 'blogs';
		})
		let category7 = navLinks.filter((item, i) => {
			return item.category === 'components';
		})
		let category8 = navLinks.filter((item, i) => {
			return item.category === 'reports';
		})
		let category9 = navLinks.filter((item, i) => {
			return item.category === 'customer';
		})
		let category10 = navLinks.filter((item, i) => {
			return item.category === 'order';
		})
		setGeneral(category1);
		setModules(category2);
		setMaterial(category3);
		setApplication(category4);
		setProduct(category5);
		setBlogs(category6);
		setComponents(category7);
		setReport(category8);
		setCustomer(category9);
		setOrder(category10);
	}

	return (
      <Box className="horizontal-menu">
         <ul className="list-unstyled nav">
				<li className="nav-item menu-item-has-child">
					<span>
						<i className="material-icons-outlined">dashboard</i>
                  <span className="menu-title">Dashboard</span>
					</span>
					<ul className="list-unstyled sub-menu">
						{general && general.map((menu, key) => (
							<NavMenuItem
								menu={menu}
								key={key}
							/>
						))}
					</ul>
				</li>   
         </ul>
      </Box>   
	);
}

export default HorizontalMenu;