const initialState = {
    // status: undefined,
    data: [],
    total: 1,
    params: {},
    allData: [],
}

const formInfoReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'CREATE_INFORMATION':
            return {...state}
        case 'UPDATE_INFORMATION':
            return { ...state }
        case 'GET_DATA_INFORMATION':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}
export default formInfoReducer
