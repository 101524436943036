const initialState = {
    data: [],
    total: 1,
    params: {},
    orderData: [],
    branchData:[],
    shipData:[]
  }
  
  const purchaseHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_PURCHASE_LIST':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_PURCHASE_DATA':
          return {
            ...state,
            allData: action.allData,
            data: action.data,
            total: action.totalPages,
            params: action.params
        }
      default:
        return { ...state }
    }
  }
  export default purchaseHistoryReducer
  