const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    productData: [],
    // reportingManager: [],
    // change_PasswordData:[],
  }
  
  const purchaseMasterReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_PURCHASE_DATA':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CREATE_PURCHASE_DATA':
        return { ...state }
      case 'UPDATE_PURCHASE_DATA':
        return { ...state }
      case "GET_PRODUCT_DATA":
        return {
          ...state,
          productData: action.data,
        };
      case "GET_SUPPLIER_DATA":
        return {
          ...state,
          supplierData: action.data,
        };

        case "GET_UNIT_DATA":
        return {
          ...state,
          unitData: action.data,
        };
      
      default:
        return { ...state }
    }
  }
  export default purchaseMasterReducer
  