const initialState = {
  data: [],
  total: 1,
  params: {},
  // allData: [],
  // designationData: [],
  // reportingManager: [],
  // change_PasswordData:[],
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_NOTIFICATION':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_NOTIFICATION':
      return { ...state }
    case 'UPDATE_NOTIFICATION':
      return { ...state }
    case 'DELETE_CITY':
      return { ...state }
  
    default:
      return { ...state }
  }
}
export default notificationReducer
