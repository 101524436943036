const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    categoryData: [],
    subCategoryData:[],
    // reportingManager: [],
    // change_PasswordData:[],
  }
  
  const subcategoryReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_SECOND_SUB_CATEGORY':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CREATE_SECOND_SUB_CATEGORY':
        return { ...state }
      case 'UPDATE_SECOND_SUB_CATEGORY':
        return { ...state }

      case "GET_CATEGORY_DATA":
          return {
            ...state,
            categoryData: action.data,
          };

      case "GET_SUB_CATEGORY_DATA":
          return {
            ...state,
            subCategoryData: action.data,
          };
    
      default:
        return { ...state }
    }
  }
  export default subcategoryReducer
  