// ** Checks if an object is empty (returns boolean)
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AWS from 'aws-sdk';
import firebase from '@firebase/app';
import { NotificationManager } from "react-notifications";


export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

const MySwal = withReactContent(Swal)
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: 'login session has been expired ?',
    text: "login again to continue accessing your account !",
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    confirmButtonText: 'Yes, logout it!', //how to add css to this button?
    customClass: {
      btn : 'btn btn-danger',
      confirmButton: "btn btn-success",
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    allowOutsideClick: false,
    buttonsStyling: true,

  }).then(function (result) {
    if (result.value) {
      firebase
        .auth()
        .signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/"
    }
  })

}
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  response => response,
  error => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      return Promise.reject(error);
    }

  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export { Axios, AxiosInstance }

//-------------------image upload on s3--------------------------//
AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION
});

const s3 = new AWS.S3({
  apiVersion: '2012-10-17',
  // params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME }
  params: {
    Bucket: "e-comdemo-images-data",
  },
});

export const uploadImageToS3 = (file, setisLoader, foldername, height, width) => {
  const allowedExtensions = ["png", "jpg", "jpeg"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

   // Generate a 4-digit random number
   const randomNumber = Math.floor(1000 + Math.random() * 9000);
  
   // Get current timestamp
   const timestamp = Date.now();
   
   // Combine randomNumber and timestamp for the file name
   const filePath = `${foldername}/${timestamp}_${randomNumber}.${fileExtension}`;
   
  const params = {
    Key: filePath,
    Body: file,
    ACL: 'public-read',
    ContentType: file.type,
    Metadata: {
      ...(height ? {'x-amz-meta-height': height.toString()} : {}),
      ...(width ? {'x-amz-meta-width': width.toString()} : {})
  }
    
  };
  
  setisLoader(true);
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        setisLoader(false);

        NotificationManager.error("File upload failed", "Error");
        resolve("");
      } else {
        // NotificationManager.success("File uploaded successfully", "Success");
        const webpLocation = data.key.replace(/\.[^/.]+$/, ".webp");
    const  imgUrl= `https://e-comdemo-images.s3.ap-south-1.amazonaws.com/${webpLocation}`;
        // "https://e-comdemo-images-data.s3.ap-south-1.amazonaws.com/4107_1709119309455.jpg"
        // resolve(imgUrl);
        setTimeout(() => {
          setisLoader(false);
          resolve(imgUrl); // Resolve after 2 seconds delay
        }, 5000);
      }
    });
  });
};

export const uploadVideo = (file,setisLoader,productVideos) =>{
  const allowedExtensions = ["mp4", "mkv"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only mp4, mkv file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  const params = {
    Key: `${productVideos}/${Date.now()}_${file.name}`,
    Body: file,
    ACL: 'public-read',
    ContentType: file.type
  };
  setisLoader(true);
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      setisLoader(false);
      if (err) {
        NotificationManager.error("File upload failed", "Error");
        resolve("");
      } else {
        // NotificationManager.success("File uploaded successfully", "Success");
        resolve(data.Location);
      }
    });
  });
} 

// export const deleteImageFromS3 = (filepath) => {
//   const params = {
//     Key: filepath
//   };

//   return new Promise((resolve, reject) => {
//     s3.deleteObject(params, (err, data) => {
//       if (err) {
//         resolve("");
//       } else {
//         resolve(data);
//       }
//     });
//   });
// };

export const deleteImageFromS3 = (
  e,
  filepath,
  setShowPreview,
) => {
  const bucketName = "e-comdemo-images";
  const s3 = new AWS.S3({
    apiVersion: "2006-03-01",
  });

  const params = {
    Bucket: bucketName,
    Key: filepath.split("/").slice(3).join("/"),
  };

  s3.deleteObject(params, (err, data) => {
    if (data) {
      // Handle UI updates after successful deletion
      setShowPreview(false);

    } else {
      console.log("Check if you have sufficient permissions : " + err);
    }
  });
};



//Upload Thumbnail image to s3.
export const UPLOAD_THUMBNAIL_IMAGE = async (
  event,
  setThumbnailFile,
  setShowPreviewThumbnail,setisLoader
) => {
  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };
  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (extension === "png" || extension === "jpg" || extension === "jpeg") {
    // const bucketName = extension === "pdf" ? "luke-images" : "luke-images-data";
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: {
        Bucket: "relocare",
      },
    });
    setisLoader(true);
    // document.getElementById(data.progressBar).style.width = "0%";

    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    for (let i = 0; i < event.target.files.length; i++) {
      const imageFile = event.target.files[i];

      if (imageFile.size <= 1000000) {
        const filePath = `vendor/${data.subfolderName
          }/${date}/${uuidv4()}.${extension}`;

        try {
          await s3
            .upload({
              Key: filePath,
              Body: imageFile,
              ACL: "public-read",
            })
            .promise();
            setisLoader(false); 

          const fileUrl = `https://relocare.s3.ap-south-1.amazonaws.com/${filePath}`;
          NotificationManager.success("File uploaded successfully", "Success");

          // You can use setState or some other state management mechanism to update UI
          setThumbnailFile(fileUrl);
          setShowPreviewThumbnail(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "File size needs to be less than or equal to 1mb",
          "File name: " + imageFile.name
        );
        setisLoader(false); 

      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      "Only JPG or PNG or JPEG file types are allowed",
      "Error"
    );
  }
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};



/********************upload video function for form information master**************/
export const UPLOAD_VIDEOS = async (
  file,
  setIsLoading,
  folderName
) => {
  setIsLoading(true);
  const extension = file.name.split(".").pop().toLowerCase();

  if (file.type.includes("video/")) {
    // const today = new Date();
    // const date =
    //   today.getDate() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   today.getFullYear();

      
   // Generate a 4-digit random number
   const randomNumber = Math.floor(1000 + Math.random() * 9000);
  
   // Get current timestamp
   const timestamp = Date.now();
      const isVideo = file.type.includes("video/");
      if (isVideo && file.size <= 51000000) {

        const filePath = `${folderName}/${timestamp}_${randomNumber}.${extension}`;
        const params = {
          Key: filePath,
          Body: file,
          ACL: 'public-read',
          ContentType: file.type,
        };
        return new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            if (err) {
              setIsLoading(false);
      
              NotificationManager.error("File upload failed", "Error");
              resolve("");
            } else {
          const fileUrl = `https://e-comdemo-images.s3.ap-south-1.amazonaws.com/${filePath}`;   
         
              setTimeout(() => {
                setIsLoading(false);
                resolve(fileUrl); // Resolve after 2 seconds delay
              }, 1000);
            }
          });
        });
        
        }else {
        NotificationManager.error(
          "Video size needs to be less than or equal to 50mb",
          "File name: " + file.name
        );
      }
  } else {
    NotificationManager.error("Only video file types are allowed", "Error");
  }
  setIsLoading(false)
}