const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const MenuItems = [
  {
    menu_title: "Dashboard",
    path: "/app/dashboard",
    icon: "dashboard",
    category: "general",
    isMenuOpen: false,
    menu: "dashboard",
    fullPageMenu: false,
    desc: true,
    content: "",
    child_routes: null,
    permission:
      permission && permission.Dashboard !== undefined
        ? permission && permission.Dashboard.includes("Dashboard")
        : false,
  },
  {
    menu_title: "Master",
    path: "/app/master/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "modules",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Master !== undefined ? permission.Master.length > 0 : false,
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "User Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission?.Master && permission?.Master !== undefined
            ? permission &&
              permission?.Master &&
              permission?.Master?.includes("User_Master")
            : false,
      },
      {
        path: "/app/master/formInformation",
        menu_title: "Form-Information",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master && permission.Master !== undefined
            ? permission &&
              permission.Master &&
              permission.Master.includes("Form_information_master")
            : false,
      },
      {
        path: "/app/master/banner",
        menu_title: "Banner",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Banner_master")
            : false,
      },
      {
        path: "/app/master/category",
        menu_title: "Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Category_master")
            : false,
      },
      {
        path: "/app/master/subCategory",
        menu_title: "Sub Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("SubCategory_master")
            : false,
      },
      // {
      //   path: "/app/master/secondSubCategory",
      //   menu_title: "Second Sub Category Master",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission &&
      //         permission.Master.includes("SecondSubCategory_master")
      //       : false,
      // },
      // {
      //   path: "/app/master/blogs",
      //   menu_title: "Blogs",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: true,
      // },
      {
        path: "/app/master/unit",
        menu_title: "Unit Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Unit_Master")
            : false,
      },
      // {
      //   path: "/app/master/supplier",
      //   menu_title: "Supplier Master",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Supplier_Master")
      //       : false,
      // },
      {
        path: "/app/master/promocode",
        menu_title: "Promocode",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Promocode_master")
            : false,
      },
      // {
      //   path: "/app/master/notification",
      //   menu_title: "Notification",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: auth.includes("Notification_master"),
      // },
      {
        path: "/app/master/popup",
        menu_title: "Popup Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Popup_Master")
            : false,
      },
    ],
  },
  {
    menu_title: "Product",
    path: "/app/master/product",
    icon: <i className="fa fa-snowflake-o" aria-hidden="true"></i>,
    category: "products",
    isMenuOpen: false,
    menu: "product",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission && permission.ProductsMaster !== undefined
        ? permission && permission.ProductsMaster.includes("Products_master")
        : false,
    child_routes: null,
  },
  {
    menu_title: "Customers",
    path: "/app/views/customers",
    icon: <i className="fa fa-user" aria-hidden="true"></i>,
    category: "customer",
    isMenuOpen: false,
    menu: "customer",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.CustomersMaster !== undefined
        ? permission.CustomersMaster.includes("Customer_master")
        : false,
    child_routes: null,
  },
  {
    menu_title: "Blogs",
    path: "/app/views/blogs",
    icon: <i className="fa fa-snowflake-o" aria-hidden="true"></i>,
    category: "blogs",
    isMenuOpen: false,
    menu: "blogs",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission && permission.BlogsMaster !== undefined
        ? permission && permission.BlogsMaster.includes("Blogs_master")
        : false,
    child_routes: null,
  },

  // {
  //   menu_title: "Material Management",
  //   path: "/app/materialManagement/",
  //   icon: <i className="fa fa-cube" aria-hidden="true"></i>,
  //   category: "materialManagement",
  //   isMenuOpen: false,
  //   menu: "materialManagement",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission:
  //     permission?.MaterialManagement !== undefined
  //       ? permission?.MaterialManagement?.length > 0
  //       : false,
  //   child_routes: [
  //     {
  //       path: "/app/materialManagement/purchase",
  //       menu_title: "Purchase Inventory",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission &&
  //         permission?.MaterialManagement &&
  //         permission?.MaterialManagement !== undefined
  //           ? permission &&
  //             permission?.MaterialManagement &&
  //             permission?.MaterialManagement?.includes("Purchase_Inventory")
  //           : false,
  //     },
  //     {
  //       path: "/app/materialManagement/purchase_report",
  //       menu_title: "Inventory Report",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission &&
  //         permission?.MaterialManagement &&
  //         permission?.MaterialManagement !== undefined
  //           ? permission &&
  //             permission?.MaterialManagement &&
  //             permission?.MaterialManagement?.includes("Inventory_Report")
  //           : false,
  //     },
  //     {
  //       path: "/app/materialManagement/purchase_history",
  //       menu_title: "Purchase History",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission &&
  //         permission?.MaterialManagement &&
  //         permission?.MaterialManagement !== undefined
  //           ? permission &&
  //             permission?.MaterialManagement &&
  //             permission?.MaterialManagement?.includes("Purchase_History")
  //           : false,
  //     },
  //   ],
  // },
  {
    menu_title: "Enquiry History",
    path: "/app/reports/Orders",
    icon: <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>,
    category: "order",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission && permission.OrderHistoryMaster !== undefined
        ? permission &&
          permission.OrderHistoryMaster.includes("Order_History_master")
        : false,
    child_routes: null,
  },
  {
    menu_title: "Reports",
    path: "/app/reports/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "reports",
    isMenuOpen: false,
    menu: "reports",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.ReportMaster !== undefined
        ? permission.ReportMaster.length > 0
        : false,
    child_routes: [
      {
        path: "/app/reports/Sales",
        menu_title: "Accepted Enquiry",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Sales_master")
            : false,
      },
      {
        path: "/app/reports/AbundantCart",
        menu_title: "Abandoned Cart Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
              permission.ReportMaster.includes("Abundant_Cart_Report")
            : false,
      },
      {
        path: "/app/reports/TrendingProduct",
        menu_title: "Trending Product Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
              permission.ReportMaster.includes("Trending_Product_Report")
            : false,
      },
    ],
  },
  {
    menu_title: "Settings",
    path: "/app/master/setting",
    icon: <i className="fa fa-cog" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission && permission.SettingsMaster !== undefined
        ? permission && permission.SettingsMaster.includes("Setting_master")
        : false,
    child_routes: null,
  },
];

export default MenuItems;
