import { combineReducers } from 'redux'
import AuthUserReducer from './AuthUserReducer'
import ContactReducer from './ContactReducer'
import AppSettingsReducer from './AppSettingsReducer'
import UserSettingsReducer from './UserSettingsReducer'
import MenuListReducer from './MenuListReducer'
import AuthReducer from './auth'
import FormInfoReducer from './FormInfoReducer';
import UserMasterReducer from './master/UserMasterReducer'
import PromocodeReducer from './master/PromocodeMasterReducer'
import SettingMasterReducer from './master/SettingMasterReducer'
import NotificationMasterReducer from './master/NotificationMasterReducer'
import CategoryMasterReducer from './master/CategoryMasterReducer'
import CustomerReducer from './CustomerReducer/CustomerReducer'
import ProductMasterReducer from './master/ProductMasterReducer'
import OrdersReducer from './reports/OrdersReducer/OrdersReducer'
import SellsReducer from './reports/SellsReportReducer/SellsReducer'
import SubCategoryMasterReducer from './master/SubCategoryReducer'
import BannerMasterReducer from './master/BannerMasterReducer'
import PopupMasterReduces from './master/PopupMasterReduces'

import BlogReducer from './master/BlogReducer';
import PurchaseMasterReducer from './master/PurchaseMasterReducer';
import UnitMasterReducer from './master/UnitMasterReducer';
import SecondSubCategoryReducer from './master/SecondSubCategoryReducer';
import SupplierMasterReducer from './master/SupplierMasterReducer';
import PurchaseReportReducer from './master/PurchaseReportReducer';
import PurchaseHistoryReducer from './master/PurchaseHistoryReducer';
import AbundantCartReducer from './reports/AbundantCartReducer';
import TrendingProductReducer from './reports/TrendingProductReducer';
import DashboardReducer from './DashboardReducer'

const reducers = combineReducers({
	authUser: AuthUserReducer,
	settings: AppSettingsReducer,
	ContactReducer: ContactReducer,
	UserSettingsReducer: UserSettingsReducer,
	menuListReducer: MenuListReducer,
	authReducer: AuthReducer,
	formInfoReducer: FormInfoReducer,
	userMasterReducer: UserMasterReducer,
	promocodeReducer: PromocodeReducer,
	settingMasterReducer: SettingMasterReducer,
	notificationMasterReducer: NotificationMasterReducer,
	categoryMasterReducer: CategoryMasterReducer,
	productMasterReducer: ProductMasterReducer,
	customerReducer: CustomerReducer,
	subcategoryMasterReducer: SubCategoryMasterReducer,
	ordersReducer: OrdersReducer,
	sellsReducer: SellsReducer,
	bannerMasterReducer: BannerMasterReducer,
	popupMasterReduces: PopupMasterReduces,
	blogReducer: BlogReducer,
	purchaseMasterReducer: PurchaseMasterReducer,
	unitMasterReducer: UnitMasterReducer,
	secondSubCategoryReducer: SecondSubCategoryReducer,
	supplierMasterReducer: SupplierMasterReducer,
	purchaseReportReducer: PurchaseReportReducer,
	purchaseHistoryReducer: PurchaseHistoryReducer,
	abundantCartReducer: AbundantCartReducer,
	trendingProductReducer: TrendingProductReducer,
	dashboardReducer: DashboardReducer

});

export default reducers;