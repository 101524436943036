/**
 * Router service file
*/

// Async component
import {
	AsyncDashboard1Component,
	AsyncFormInformationComponent,
	AsyncUserMasterComponent,
	AsyncUserSettingsComponent,
	AsynCategoryComponent,
	AsynPromocodeComponent,
	AsynSettingComponent,
	AsynProductComponent,
	//list
	AsynOrderListComponent,
	AsynCustomerListComponent,
	AsynSellsReportComponent,
	AsynSubCategoryComponent,
	AsyncBannerMasterComponent,
	AsynPopupComponent,
	AsynBlogsComponent,
	AsynUnitComponent,
	AsynSecondSubCategoryComponent,
	AsynSupplierComponent,
	AsynAbundantCartReportComponent,
	AsynTrendingProductReportComponent,
	AsynPurchaseComponent,
	AsynPurchaseHistoryComponent,
	AsynPurchaseReportComponent,

} from 'routes/ComponentLoader';
const permission = JSON.parse(localStorage.getItem("userData"))
	? JSON.parse(localStorage.getItem("userData")).authentication
	: {};
const routes = [
	//Dashboard's routes & permissions.
	{
		path: 'dashboard',
		component: AsyncDashboard1Component,
		meta: { permission: true },
	},
	//Master's routes & permissions.
	{
		path: 'master/user',
		component: AsyncUserMasterComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("User_Master")
				: false
		},
	},
	// {
	// 	path: 'master/report',
	// 	component: AsyncUserMasterComponent,
	// 	meta: { permission: true },
	// },
	{
		path: 'master/formInformation',
		component: AsyncFormInformationComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Form_information_master")
				: false
		},
	},
	{
		path: 'master/banner',
		component: AsyncBannerMasterComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Banner_master")
				: false
		},
	},
	{
		path: 'master/category',
		component: AsynCategoryComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Category_master")
				: false
		},
	},
	{
		path: 'master/subCategory',
		component: AsynSubCategoryComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("SubCategory_master")
				: false
		},
	},
	{
		path: 'materialManagement/purchase',
		component: AsynPurchaseComponent,
		meta: {
			permission: permission && permission.MaterialManagement !== undefined
				? permission && permission.MaterialManagement.includes("Purchase_Inventory")
				: false
		},
	},
	{
		path: 'materialManagement/purchase_report',
		component: AsynPurchaseReportComponent,
		meta: {
			permission: permission && permission.MaterialManagement !== undefined
				? permission && permission.MaterialManagement.includes("Inventory_Report")
				: false
		},
	},
	{
		path: 'materialManagement/purchase_history',
		component: AsynPurchaseHistoryComponent,
		meta: {
			permission: permission && permission.MaterialManagement !== undefined
				? permission && permission.MaterialManagement.includes("Purchase_History")
				: false
		},
	},
	{
		path: 'master/promocode',
		component: AsynPromocodeComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Promocode_master")
				: false
		},
	},

	// {
	// 	path: 'master/notification',
	// 	component: AsynNotificationComponent,
	// 	meta: {
	// 		permission: permission && permission.Master !== undefined
	// 			? permission && permission.Master.includes("Promocode_master")
	// 			: false
	// 	},
	// },
	{
		path: 'master/setting',
		component: AsynSettingComponent,
		meta: {
			permission: permission && permission.SettingsMaster !== undefined
				? permission && permission.SettingsMaster.includes("Setting_master")
				: false
		},
	},
	{
		path: 'master/product',
		component: AsynProductComponent,
		meta: {
			permission: permission && permission.ProductsMaster !== undefined
				? permission && permission.ProductsMaster.includes("Products_master")
				: false
		},
	},

	//list
	{
		path: 'reports/orders',
		component: AsynOrderListComponent,
		meta: {
			permission: permission && permission.OrderHistoryMaster !== undefined
				? permission && permission.OrderHistoryMaster.includes("Order_History_master")
				: false
		},
	},
	{
		path: 'views/customers',
		component: AsynCustomerListComponent,
		meta: {
			permission: permission && permission.SettingsMaster !== undefined
				? permission && permission.SettingsMaster.includes("Setting_master")
				: false
		},
	},
	// {
	// 	path: 'views/ProductAvalibilityUpdate',
	// 	component: AsynProductAvailibilityUpdateComponent,
	// 	meta: { permission: true },
	// },
	// {
	// 	path: 'reports/BranchSales',
	// 	component: AsynBranchSellsReportComponent,
	// 	meta: {
	// 		permission: permission && permission.ReportMaster !== undefined
	// 			? permission && permission.ReportMaster.includes("Setting_master")
	// 			: false
	// 	},
	// },
	{
		path: "reports/Sales",
		component: AsynSellsReportComponent,
		meta: {
			permission: permission && permission.ReportMaster !== undefined
				? permission && permission.ReportMaster.includes("Sales_master")
				: false
		},
	},
	{
		path: "reports/AbundantCart",
		component: AsynAbundantCartReportComponent,
		meta: {
			permission: permission && permission.ReportMaster !== undefined
				? permission && permission.ReportMaster.includes("Abundant_Cart_Report")
				: false
		},
	},
	{
		path: "reports/TrendingProduct",
		component: AsynTrendingProductReportComponent,
		meta: {
			permission: permission && permission.ReportMaster !== undefined
				? permission && permission.ReportMaster.includes("Trending_Product_Report")
				: false
		},
	},
	{
		path: "views/blogs",
		component: AsynBlogsComponent,
		meta: {
			permission: permission && permission.BlogsMaster !== undefined
				? permission && permission.BlogsMaster.includes("Blogs_master")
				: false
		},
	},
	{
		path: "master/unit",
		component: AsynUnitComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Unit_Master")
				: false
		},
	},
	{
		path: "master/secondSubCategory",
		component: AsynSecondSubCategoryComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("SecondSubCategory_master")
				: false
		},
	},
	{
		path: "master/supplier",
		component: AsynSupplierComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Supplier_Master")
				: false
		},
	},


	{
		path: 'user-settings',
		component: AsyncUserSettingsComponent,
		meta: {
			permission: permission && permission.SettingsMaster !== undefined
				? permission && permission.SettingsMaster.includes("Setting_master")
				: false
		},
	},
	{
		path: 'master/popup',
		component: AsynPopupComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Popup_Master")
				: false
		},
	},
	//    {
	// 		path: 'sample-form',
	//       component:AsyncSampleFormComponent
	// 	}
]

export default routes;