import { NotificationManager } from 'react-notifications';
import { Axios } from '../../../../util/index';


//-----------get user list with paginated data--------------------------// 
export const getUsersData = (params, setisLoader) => {
  setisLoader(true)
  return dispatch => {
    Axios.get(`/api/panel/users/paginated?page=${params.page}&limit=${params.perPage}&search=${params.search}`).then(response => {
      dispatch({
        type: 'GET_DATA_USER',
        allData: response.data.data.total,
        data: response.data.data.docs,
        totalPages: response.data.data.pages,
        params
      })
    })
      .catch(error => {
        dispatch({
          type: 'GET_DATA_USER',
          allData: 0,
          data: [],
          totalPages: 0,
          params
        })
      }).finally(() => {
        setisLoader(false)
      })

  }
}

//-----------------create new user--------------------------//
export const createNewUser = (data, props, setisLoader) => {
  return (dispatch, getStore) => {
    Axios.post(`/api/panel/users`, {
      name: data.name,
      mobile_number: `+91${data.mobile_number}`,
      email: data.email,
      status: JSON.parse(data.status),
      user_type: data.user_type,
      branch_id: data.branch_id,
      authentication: data.authentication,
    })
      .then((response) => {
        if (response.data.status === false) {
          NotificationManager.error(response.data.message);
        } else {
          props.onMasterSave();
          NotificationManager.success(response.data.message);

          return dispatch => {
            dispatch(
              {
                type: 'CREATE_USER',
                data
              }
            )
          }
        }
      })
      .then(() => dispatch(getUsersData(getStore().userMasterReducer.params, setisLoader)))
      .catch((error) => {
        NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');

      })

  }
}

//------------------update user information------------------------//
export const updateUser = (data, props, setisLoader) => {
  return (dispatch, getStore) => {
    Axios.put(`/api/panel/users/${props.preloadValue.detail._id}`, {
      name: data.name,
      mobile_number: `+91${data.mobile_number}`,
      email: data.email,
      status: JSON.parse(data.status),
      user_type: data.user_type,
      branch_id: data.branch_id,
      authentication: data.authentication,
    })
      .then(response => {
        if (response.data.status === false) {
          NotificationManager.error(response.data.message);

        } else {
          props.onMasterSave();
          NotificationManager.success(response.data.message);
          return dispatch => {
            dispatch(
              {
                type: 'UPDATE_USER',
                data
              }
            )
          }
        }
      })
      .then(() => dispatch(getUsersData(getStore().userMasterReducer.params, setisLoader)))
      .catch(function (error) {
        NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');

      })

  }
}



